import 'jquery-ajax-unobtrusive';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

export default class TabComponent {
    constructor($view) {
        this.view = $view;
        this.NbTab = 0;
        this.Init();
    }

    CheckForErrors() {
        var self = this;

        // On va checker si y'a des champs en erreur
        var $firstInputError = $("form").find('.input-validation-error, .field-validation-error').first();

        // Si y'a une erreur
        if ($firstInputError.length > 0) {
            // On va tenter d'afficher le bon tab
            var $tab = $firstInputError.closest('.tab-pane');

            // On active le premier tab en erreur
            $('a[href="#' + $tab[0].id + '"].list-group-item', self.view).tab('show');

            // On le met dans l'ancre
            window.location.hash = $tab[0].id;

            // On va gérer la progress bar
            // On récupère d'abord l'index de l'élément clické
            var tabNumber = $(".list-group .list-group-item").index($('a[href="#' + $tab[0].id + '"].list-group-item', self.view)) + 1;
            var progressValue = (tabNumber / self.NbTab) * 100;

            // On set la progress bar
            $(".progress .progress-bar").attr('style', 'width:' + progressValue + '%;')
            $(".progress .progress-bar").attr('aria-valuenow', progressValue)
        }
    }

    Init() {
        var self = this;

        // on compte le nombre de tab
        self.NbTab = $(".list-group .list-group-item", self.view).length;

        // On récupère l'ancre
        var hash = window.location.hash;

        // On active le bon tab
        $('a[href="' + hash + '"].list-group-item', self.view).tab('show');

        // On va gérer la progress bar
        // On récupère d'abord l'index de l'élément clické
        var tabNumber = $(".list-group .list-group-item").index($('a[href="' + hash + '"].list-group-item')) + 1;

        if (tabNumber < 1) {
            // Afin d'être correctement positionné quand il n'y a aucune ancre
            tabNumber = 1;
        }
        var progressValue = (tabNumber / self.NbTab) * 100;


        // On set la progress bar
        $(".progress .progress-bar").attr('style', 'width:' + progressValue + '%;')
        $(".progress .progress-bar").attr('aria-valuenow', progressValue)

        self.CheckForErrors();

        // Gestion du click sur un lien
        self.view.on("click", '.list-group .list-group-item', (e) => {

            e.preventDefault();

            // On récupère l'id du tab
            var id = $(e.target).attr("href").substr(1);

            // On le met dans l'ancre
            if (history.pushState) {
                // Cette méthode permet de ne pas scroller lors de l'écriture du hash dans l'url
                history.pushState(null, null, '#' + id);
            }
            else {
                location.hash = id;
            }

            // On va gérer la progress bar
            // On récupère d'abord l'index de l'élément clické
            var tabNumber = $(".list-group .list-group-item").index(e.target) + 1;
            console.log(tabNumber);

            var progressValue = (tabNumber / self.NbTab) * 100;

            // On set la progress bar
            $(".progress .progress-bar").attr('style', 'width:' + progressValue + '%;');
            $(".progress .progress-bar").attr('aria-valuenow', progressValue);

            // Pour ne pas suivre l'ancre
            return false;
        });

        // Si on a un unique form dans la page
        if ($("form").length === 1) {

            // Pour faire en sorte de valider les champs cachés dans les tab non affichés, mais pas les hidden field
            $("form").data("validator").settings.ignore = "input[type='hidden']";

            // On récupère le submit du form
            $("form").submit(function (event) {

                self.CheckForErrors();
            });
        }
    }
}