import Swal from "sweetalert2/dist/sweetalert2";
import toastr from 'toastr';

export default class ProduitFichiers {

    constructor($view) {
        console.log('ProduitFichiers');
        this.$view = $view;
        this.SetImagePrincipaleUrl = this.$view.data('setimageprincipaleurl');
        this.ReorderUrl = this.$view.data('reorder-url');
        this.Init();
        this.initializeKendoFunctions();
    }

    Init() {

        var self = this;

        // on s'abonne au click sur le bouton de set de l'image principale

        $(self.$view).on('click', '.js-set-imageprincipale', function (e) {
            
            Swal.fire({
                title: 'Définir comme image principale ?',
                text: 'Voulez vous définir cette image comme image principale ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non'
            }).then((result) => {
                if (result.isConfirmed) {

                    $.ajax({
                        url: self.SetImagePrincipaleUrl,
                        // Seul DataSourceRequest est envoyé dans le body
                        data: { urlImage: $(this).data('urlimage') },
                        type: 'POST',
                        success: function (data) {
                            window.location.reload();
                        },
                        error: function (error) {
                            toastr.error('Une erreur est survenue');
                        }
                    });
                }
            })
        });
    }
    // Fonction pour initialiser les fonctions Kendo
    initializeKendoFunctions() {
        window.placeholder = (element) => {
            return element.clone().css("opacity", 0.1);
        };
        window.hint= (element) => {
            return element.clone().removeClass("k-selected");
        }
        window.onChange = (e) => {
            console.log("oldIndex: ", e.oldIndex, "newIndex: ", e.newIndex);
            var listView = $("#listView").data("kendoListView"),
                dataSource = listView.dataSource,
                oldIndex = e.oldIndex,
                newIndex = e.newIndex,
                data = dataSource.data(),
                dataItem = dataSource.getByUid(e.item.data("uid"));

            dataSource.remove(dataItem);
            dataSource.insert(newIndex, dataItem);

            var data = {
                // Si on est en descente on incrémente de 1
                newOrder: newIndex + 1,
                id: dataItem.Id
            };

            $.ajax({
                url: this.ReorderUrl,
                type: 'POST',
                data: data,
                success: function (data) {

                    window.location.reload();
                    toastr.success("Mise à jour de l'ordre");
                }
            });
        }
    }
}
document.addEventListener('DOMContentLoaded', () => {

    window.closeImageDetails = () => {
        var imageDetails = document.getElementById('imageDetails');

        imageDetails.classList.add('d-none');
        imageDetails.classList.remove('d-flex');
    };

    window.showImageDetails = (urlDetailProduitFichier) => {
        var imageDetails = document.getElementById('imageDetails');
        $.ajax({
            url: urlDetailProduitFichier,
            type: 'GET',
            success: function (response) {
                $('#partial').html(response);

                imageDetails = document.getElementById('imageDetails');

                if (imageDetails) {
                    imageDetails.classList.remove('d-none');
                    imageDetails.classList.add('d-flex');
                }
            },
            error: function () {
                toastr.error('Une erreur est survenue lors du rechargement du fichier.');
            }
        });

        // Ajout/Suppression d'une bordure pour le fichier sélectionné
        document.querySelectorAll('.imageKendo').forEach(element => {
            element.classList.remove('border-primary');
        });
        const clickedElement = event.currentTarget; // Utilise l'événement courant pour obtenir l'élément cliqué
        clickedElement.classList.add('border-primary');

    };
});